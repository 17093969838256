import * as React from 'react';
import styled from 'styled-components';
import { Text, Theme } from '@audi/audi-ui-react';
import {
  renderTextWithFootnotesReferencesV2,
  ConsumptionsAndEmissions,
} from '@oneaudi/feature-app-utils';
import type { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { OneGraphServiceV1 } from '@oneaudi/onegraph-service';
import type { Logger } from '@feature-hub/logger';

const LegalInfoContainer = styled.div`
  margin-block-start: ${({ theme }: { theme: Theme }) => `var(${theme.responsive?.spacing.l})`};
`;

interface Props {
  consumptionAndEmissions: string[];
  disclaimers?: string[];
  localeService: LocaleServiceV1;
  oneGraphService: OneGraphServiceV1;
  loggerService?: Logger;
}

export const LegalInfo: React.FC<Props> = ({
  disclaimers,
  consumptionAndEmissions,
  oneGraphService,
  localeService,
  loggerService,
}) => (
  <LegalInfoContainer>
    {consumptionAndEmissions && (
      <Text variant="copy2" spaceStackEnd="xs">
        <ConsumptionsAndEmissions
          ids={consumptionAndEmissions}
          localeService={localeService}
          oneGraphService={oneGraphService}
          logger={loggerService}
        />
      </Text>
    )}
    {disclaimers?.map((x, i) => (
      <Text key={[i, x].join('_')} variant="copy2" spaceStackEnd="xs">
        {renderTextWithFootnotesReferencesV2(x)}
      </Text>
    ))}
  </LegalInfoContainer>
);
