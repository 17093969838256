/* eslint-disable @typescript-eslint/no-shadow */

import * as React from 'react';

import type { XOR } from 'ts-xor';

import { TrackingDataV2, TrackingEventV2, TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { APP_NAME } from './utils';

const componentNameWithSpaces = APP_NAME;
const componentNameWithDashes = componentNameWithSpaces.replace(/ /g, '-');

type EventAction =
  | 'page_load'
  | 'step'
  | 'opt-in'
  | 'view_change'
  | 'internal_link'
  | 'impression'
  | 'feature_app_ready'
  | 'exit_link'
  | 'download'
  | 'image'
  | 'video'
  | 'configuration'
  | 'conflict'
  | 'favorite'
  | 'call'
  | 'anchor'
  | 'email'
  | 'content'
  | 'navigation'
  | 'tooltip'
  | 'error'
  | 'filter'
  | 'form_field'
  | 'non_interaction'
  | 'print'
  | 'user_account_change'
  | 'rating'
  | 'compare'
  | 'order';

export type TrackingClickEvent = TrackingEventV2['attributes'] & {
  name?: string;
} & XOR<
    {
      action?: EventAction;
      targetURL: string;
    },
    {
      action: EventAction;
      targetURL?: string;
    }
  >;

export type TrackClickEvent = (event: TrackingClickEvent) => void;

const createEvent = ({
  attributes,
  action,
  name,
}: {
  attributes?: TrackingEventV2['attributes'];
  action: EventAction;
  name: string;
}) => ({
  event: {
    attributes: {
      componentName: componentNameWithDashes,
      ...attributes,
    },
    eventInfo: {
      eventAction: action,
      eventName: name,
    },
  },
});

const trackClickEvent = (
  tracking: TrackingServiceV2,
  { name, action: _action, ...attributes }: TrackingClickEvent,
): void => {
  const action =
    _action ||
    ((attributes.targetURL ? tracking.evaluateLinkType(attributes.targetURL) : '') as
      | EventAction
      | undefined);
  if (action)
    tracking.track(
      createEvent({
        action,
        name: `${componentNameWithSpaces} - ${
          name ? `${name}` : `click on ${attributes.elementName || 'UNDEFINED'}`
        }`,
        attributes,
      }),
    );
};

type UseTrackingReturnValue = {
  service: TrackingServiceV2 | undefined;
  registerImpressionTracking: (el: Element, attributes?: TrackingEventV2['attributes']) => void;
  sendReadyEvent: (attributes?: TrackingEventV2['attributes']) => void;
  sendClickEvent: (event: TrackingClickEvent) => void;
};

export const useTracking = (service?: TrackingServiceV2): UseTrackingReturnValue => {
  if (typeof window === 'undefined' || typeof service === 'undefined') {
    // skip tracking initialization when no window object is present (=> SSR)
    return {
      service: undefined,
      registerImpressionTracking: () => null,
      sendReadyEvent: () => null,
      sendClickEvent: () => null,
    };
  }

  const sendReadyEvent = React.useCallback(() => {
    const attribute = {
      action: 'feature_app_ready' as EventAction,
      name: `${componentNameWithSpaces} - feature app ready`,
      attributes: {
        componentName: componentNameWithDashes,
        elementName: '',
        label: '',
        clickID: '',
        pos: '',
        targetURL: '',
        value: '',
      },
    };
    const toBeTracked = createEvent(attribute);
    return service.track(toBeTracked as unknown as TrackingDataV2);
  }, [service]);

  const registerImpressionTracking = React.useCallback(
    (
      el: Parameters<typeof service.registerImpressionTracking>[0],
      attributes?: TrackingEventV2['attributes'],
    ) =>
      service.registerImpressionTracking(el, () => ({
        event: {
          eventInfo: {
            eventAction: 'impression',
            eventName: `${componentNameWithSpaces} - impression`,
          },
          attributes: {
            componentName: componentNameWithDashes,
            label: '',
            targetURL: '',
            clickID: '',
            elementName: '',
            pos: '',
            ...attributes,
          },
        },
      })),
    [service],
  );

  const sendClickEvent = React.useCallback(
    (event: TrackingClickEvent) => trackClickEvent(service, event),
    [],
  );

  return {
    service,
    registerImpressionTracking,
    sendReadyEvent,
    sendClickEvent,
  };
};
