import {
  Asset,
  ContentFragment,
  InvalidContentError,
  isContentFragment,
  ContentReference,
} from '@oneaudi/falcon-tools';
/* eslint-disable camelcase */
import {
  EditorialTeaserComponent,
  EditorialTeaserContent,
  HeadingTag,
  ThemeColor,
} from '../../types';

function isFalconContent(content: unknown): content is FalconContent {
  return Boolean(isContentFragment(content));
}

const normalizeContent = (componentsArray: ComponentContent[]): EditorialTeaserComponent[] => {
  return componentsArray.map((component: ComponentContent) => {
    const {
      imageAsset_src,
      image_src,
      image_alt,
      heading,
      headingTag,
      text,
      links_0_ariaLabel,
      links_0_openInNewTab,
      links_0_label,
      links_0_url,
      links_1_ariaLabel,
      links_1_label,
      links_1_openInNewTab,
      links_1_url,
      consumptionAndEmissions,
      disclaimers,
    } = component.fields;

    const itemArray: EditorialTeaserComponent = {
      imageAsset: {
        src: {
          assetValue: imageAsset_src ? (imageAsset_src as Asset)?.path : image_src,
        },
      },
      image: {
        src: image_src,
        alt: image_alt,
      },
      heading,
      headingTag,
      text,
      links: [
        {
          label: links_0_label,
          url: links_0_url,
          openInNewTab: links_0_openInNewTab,
          ariaLabel: links_0_ariaLabel,
        },
        {
          label: links_1_label,
          url: links_1_url,
          openInNewTab: links_1_openInNewTab,
          ariaLabel: links_1_ariaLabel,
        },
      ],
      consumptionAndEmissions,
      disclaimers,
    };

    return itemArray;
  });
};

export interface FalconContent extends Partial<ContentFragment> {
  __type: 'aem-headless';
  fields: {
    themeColor: ThemeColor;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    components: any;
  };
}
export interface EditorJsonContent {
  __type?: 'editor.json'; // This will in reality never be set, but it makes mapping easier
  components: EditorialTeaserComponent[];
  themeColor?: ThemeColor;
}

export interface ComponentContent extends EditorialTeaserContent {
  fields: {
    imageAsset_src: ContentReference;
    image_src: string;
    image_alt: string;
    heading: string;
    headingTag: HeadingTag;
    text: string;
    links_0_label: string;
    links_0_url: string;
    links_0_ariaLabel: string | null;
    links_0_openInNewTab: boolean;
    links_1_label: string;
    links_1_url: string;
    links_1_ariaLabel: string | null;
    links_1_openInNewTab: boolean;
    consumptionAndEmissions: string[];
    disclaimers: string[];
  };
}

export function mapContent(content: EditorJsonContent | FalconContent): EditorJsonContent {
  if (!content || Object.keys(content).length === 0) {
    // eslint-disable-next-line no-console
    console.warn('No Editor content found for Editorial Teaser');
    return {} as EditorJsonContent;
  }

  if (content.__type !== 'aem-headless') {
    return content as EditorJsonContent;
  }

  if (content.__type === 'aem-headless' && !isFalconContent(content)) {
    throw new InvalidContentError('Content is invalid.');
  }

  const normalizedContent: EditorJsonContent = {
    themeColor: content.fields.themeColor,
    components: normalizeContent(content.fields.components),
  };
  return normalizedContent;
}
